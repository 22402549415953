/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { FormattedMessage, useIntl } from "react-intl";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import userApi from "../../../api/userApi";
import { useAuth } from "../core/Auth";

const GoogleLogin = ({ setStatus }: any) => {
  const intl = useIntl();
  const { saveAuth, setCurrentUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (credentialResponse: TokenResponse) => {
      loginSuccessHandler(credentialResponse);
    },
    onError: (error) => {
      setLoading(false);
      setStatus(intl.formatMessage({ id: "auth.login.error.google" }));
    },
  });

  const loginSuccessHandler = async (credentialResponse: TokenResponse) => {
    try {
      setLoading(true);
      const { data: auth } = await userApi.googleLogin(
        credentialResponse.access_token
      );
      saveAuth(auth);
      const { data: user } = await userApi.getCurrentUser();
      setCurrentUser(user);
    } catch (error) {
      setStatus(intl.formatMessage({ id: "auth.login.error.google" }));
      setLoading(false);
    }
  };

  return (
    <a
      className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setStatus("");
        googleLogin();
      }}
    >
      <img
        alt="Logo"
        src={toAbsoluteUrl("/media/icons/google.svg")}
        className="h-15px me-3"
      />
      {loading ? (
        <span className="indicator-progress" style={{ display: "block" }}>
          <FormattedMessage id="common.pleaseWait" />
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        <FormattedMessage id="auth.loginWithGoogle" />
      )}
    </a>
  );
};

export default GoogleLogin;
