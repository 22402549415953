import httpClient from "./httpClient";

const getCurrentUser = () => {
  return httpClient.get("/user/me");
};

const updateUserPreferences = (
  default_language: string | undefined,
  interface_language: string | undefined,
  theme: string | undefined
) => {
  return httpClient.put("/user/preferences", {
    default_language,
    interface_language,
    theme,
  });
};

const login = (email: string, password: string) => {
  return httpClient.post("/user/login", {
    email,
    password,
  });
};

const signUp = (email: string, password: string) => {
  return httpClient.post("/user/sign-up", {
    email,
    password,
  });
};

const verifyEmail = (email: string, verification_code: string) => {
  return httpClient.post("/user/verify-email", {
    email,
    verification_code,
  });
};

const resendVerificationCode = (email: string) => {
  return httpClient.post(
    "/user/resend-verification-code",
    {},
    {
      params: { email },
    }
  );
};

const forgotPassword = (email: string) => {
  return httpClient.get("/user/forgot-password", { params: { email } });
};

const changePassword = (old_password: string, new_password: string) => {
  return httpClient.post("/user/change-password", {
    old_password,
    new_password,
  });
};

const googleLogin = (google_token: string) => {
  return httpClient.post("/user/google-login", {
    google_token,
  });
};

const userApi = {
  getCurrentUser,
  updateUserPreferences,
  signUp,
  login,
  verifyEmail,
  resendVerificationCode,
  forgotPassword,
  changePassword,
  googleLogin,
};

export default userApi;
