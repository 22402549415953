import { FC, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "../core/Auth";
import userApi from "../../../api/userApi";
import GoogleLogin from "./GoogleLogin";

const initialValues = {
  email: "",
  password: "",
};

const Login: FC = () => {
  const intl = useIntl();
  const { saveAuth, setCurrentUser } = useAuth();

  const [loading, setLoading] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "auth.email.wrongFormat" }))
      .required(intl.formatMessage({ id: "auth.email.required" })),
    password: Yup.string().required(
      intl.formatMessage({ id: "auth.password.required" })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setStatus("");
      setLoading(true);
      try {
        const { data: auth } = await userApi.login(
          values.email,
          values.password
        );
        saveAuth(auth);
        const { data: user } = await userApi.getCurrentUser();
        setCurrentUser(user);
      } catch (error) {
        console.error(error);
        setStatus(intl.formatMessage({ id: "auth.login.error" }));
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_login_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">
          <FormattedMessage id="auth.login.title" />
        </h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className="mb-lg-10 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bold text-dark">
          <FormattedMessage id="auth.email" />
        </label>
        <input
          placeholder={intl.formatMessage({ id: "auth.email" })}
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email,
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bold text-dark fs-6 mb-0">
              <FormattedMessage id="auth.password" />
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type="password"
          placeholder={intl.formatMessage({ id: "auth.password" })}
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
        <Link
          to="/auth/forgot-password"
          className="link-primary fs-6 fw-bold mt-3 mb-8"
          style={{ float: "right" }}
        >
          <FormattedMessage id="auth.forgotPassword" />
        </Link>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {" "}
              <FormattedMessage id="auth.login" />{" "}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              <FormattedMessage id="common.pleaseWait" />
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      {/* begin::OR Separator */}
      <div className="separator separator-content my-4">
        <span className="w-25px text-gray-500 fw-semibold fs-7">
          <FormattedMessage id="auth.or" />
        </span>
      </div>
      {/* end::OR Separator */}

      {/* begin::Google */}
      <div className="my-8">
        <GoogleOAuthProvider clientId="665698605234-t3i3blbk3qa7bor07rc4ps1d2frh0og7.apps.googleusercontent.com">
          <GoogleLogin setStatus={formik.setStatus} />
        </GoogleOAuthProvider>
      </div>
      {/* end::Google */}

      <div className="text-center text-gray-400 fw-semibold fs-4">
        <FormattedMessage id="auth.dontHaveAccount" />
        <Link to="/auth/sign-up" className="link-primary fw-bold mx-2">
          <FormattedMessage id="auth.signUp" />
        </Link>
      </div>
    </form>
  );
};

export default Login;
