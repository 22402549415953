/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/pages/auth";
import { Languages } from "./Languages";
import { toAbsoluteUrl } from "../../../helpers";
import { systemMode, useThemeMode } from "../theme-mode/ThemeModeProvider";
import { FormattedMessage } from "react-intl";

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const { mode } = useThemeMode();

  const _mode = mode !== "system" ? mode : systemMode;
  const userAvatar = _mode === "dark" ? "blank-dark.svg" : "blank.svg";
  const email = currentUser?.email ?? "";

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl(`/media/avatars/${userAvatar}`)}
            />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bold d-flex align-items-center text-gray-800 fs-5">
              {email?.length > 20 ? `${email.slice(0, 20)}...` : email}
            </div>
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5 my-1">
        <Link to="/subscription" className="menu-link px-5">
          <FormattedMessage id="subscription" />
        </Link>
      </div>

      <div className="menu-item px-5 my-1">
        <Link to="/wordpress-plugin" className="menu-link px-5">
          <FormattedMessage id="wordPressPlugin.title" />
        </Link>
      </div>

      <div className="menu-item px-5 my-1">
        <Link to="/user/change-password" className="menu-link px-5">
          <FormattedMessage id="user.changePassword" />
        </Link>
      </div>

      <div className="separator my-2"></div>

      <Languages />

      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          <FormattedMessage id="common.signOut" />
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
